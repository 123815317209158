import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between mb-6" }
const _hoisted_2 = { class: "float-right" }
const _hoisted_3 = ["onMousedown"]
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[20] || (_cache[20] = _createElementVNode("h2", { class: "title" }, "Agendamentos", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "movida-orange mr-3",
          onClick: _ctx.newImport
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" mdi-file-import ")
              ])),
              _: 1
            }),
            _cache[17] || (_cache[17] = _createTextVNode(" Importar "))
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_btn, {
          class: "text-white",
          color: "movida-orange",
          onClick: _ctx.newSchedule
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" mdi-plus ")
              ])),
              _: 1
            }),
            _cache[19] || (_cache[19] = _createTextVNode(" Novo agendamento "))
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_v_row, { class: "mb-1 flex justify-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "3",
          sm: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search.order,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search.order) = $event)),
              clearable: "",
              density: "compact",
              "hide-details": "",
              label: "Nª do pedido",
              "prepend-inner-icon": "mdi-magnify",
              "single-line": "",
              variant: "solo",
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "3",
          sm: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search.cpf,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search.cpf) = $event)),
              clearable: "",
              density: "compact",
              "hide-details": "",
              label: "CPF",
              "prepend-inner-icon": "mdi-magnify",
              "single-line": "",
              variant: "solo",
              onInput: _ctx.searchByNeedle,
              "onClick:clear": _ctx.clearFilter
            }, null, 8, ["modelValue", "onInput", "onClick:clear"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.search.plate,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search.plate) = $event)),
                _ctx.searchByNeedle
              ],
              label: "Placa",
              clearable: true,
              items: _ctx.allFleets,
              "item-title": "Placa",
              "item-value": "Placa",
              variant: "solo",
              density: "compact",
              multiple: ""
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.search.branch,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search.branch) = $event)),
                _ctx.searchByNeedle
              ],
              label: "Loja",
              clearable: true,
              items: _ctx.allBranch,
              "item-title": "Loja",
              "item-value": "FilialID",
              variant: "solo",
              density: "compact",
              multiple: ""
            }, null, 8, ["modelValue", "items", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "2",
          sm: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VueDatePicker, {
              modelValue: _ctx.dateSearch,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dateSearch) = $event)),
              range: "",
              vertical: "",
              format: _ctx.format,
              locale: "pt-br",
              "select-text": "selecionar",
              "cancel-text": "cancelar",
              "enable-time-picker": false,
              onClosed: _ctx.searchByNeedle,
              onRangeStart: _ctx.onRangeStart,
              onRangeEnd: _ctx.onRangeEnd,
              onCleared: _ctx.clearCalendar
            }, null, 8, ["modelValue", "format", "onClosed", "onRangeStart", "onRangeEnd", "onCleared"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      headers: _ctx.headers,
      items: _ctx.items,
      loading: _ctx.isLoadingTable,
      "hide-default-footer": "",
      "item-value": "name",
      to: "asd"
    }, {
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.id,
            class: _normalizeClass(!item.status ? 'hover-table' : ''),
            onMousedown: ($event: any) => (_ctx.mouseDownSchedule(item)),
            onMouseup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.mouseUpSchedule()))
          }, [
            _createElementVNode("td", null, _toDisplayString(item.order.id) + " - " + _toDisplayString(item.client.name), 1),
            _createElementVNode("td", null, _toDisplayString(item?.client?.cpf), 1),
            _createElementVNode("td", null, _toDisplayString(item?.fleet?.plate), 1),
            _createElementVNode("td", null, _toDisplayString(item.branch.store), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.format_date(item.scheduling_at)), 1),
            _createElementVNode("td", null, _toDisplayString(item?.scheduling_by?.name), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.format_date(item.create_at)), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_v_chip, {
                variant: "outlined",
                size: "small",
                color: _ctx.getStatusColor(item.status)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getFormatText(item.status)), 1)
                ]),
                _: 2
              }, 1032, ["color"])
            ])
          ], 42, _hoisted_3))
        }), 128))
      ]),
      bottom: _withCtx(() => _cache[21] || (_cache[21] = [])),
      _: 1
    }, 8, ["items-per-page", "headers", "items", "loading"]),
    _createVNode(_component_v_row, {
      class: "mt-2 d-flex align-center pb-10",
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_pagination, {
            modelValue: _ctx.currentPage,
            "onUpdate:modelValue": [
              _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentPage) = $event)),
              _ctx.loadPage
            ],
            length: _ctx.numberOfPages,
            "total-visible": 7,
            color: "movida-orange",
            onNext: _ctx.nextPage,
            onPrev: _ctx.prevPage
          }, null, 8, ["modelValue", "length", "onNext", "onPrev", "onUpdate:modelValue"]),
          _createElementVNode("div", null, _toDisplayString(_ctx.pageFrom) + " a " + _toDisplayString(_ctx.pageTo) + " de " + _toDisplayString(_ctx.totalOfRows) + " resultados", 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialogReagendamento,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.confirmDialogReagendamento) = $event)),
          "max-width": "500px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline mt-2" }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Agendamento ultrapassado")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Este agendamento foi realizado para uma data passada, deseja fazer o reagendamento?")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                _createVNode(_component_v_card_actions, { class: "pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.confirmDialogReagendamento = false))
                    }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Cancelar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-red rounded-lg",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.reschedule()))
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("Reagendar")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.confirmDialogImport,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.confirmDialogImport) = $event)),
          "max-width": "700px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline mt-2" }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode(" Importar CSV ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_file_input, {
                      modelValue: _ctx.csvFile,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.csvFile) = $event)),
                      accept: ".csv",
                      label: "Escolha o arquivo CSV",
                      onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.btn_disabled = false)),
                      outlined: "",
                      dense: "",
                      required: ""
                    }, null, 8, ["modelValue"]),
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                          key: 0,
                          indeterminate: ""
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                    (_ctx.results.length > 0)
                      ? (_openBlock(), _createBlock(_component_v_data_table, {
                          key: 1,
                          items: _ctx.results,
                          headers: _ctx.headers,
                          class: "mt-4"
                        }, {
                          headers: _withCtx(() => [
                            _createElementVNode("tr", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headersImport, (header) => {
                                return (_openBlock(), _createElementBlock("th", {
                                  key: header.value,
                                  class: "text-left"
                                }, _toDisplayString(header.text), 1))
                              }), 128))
                            ])
                          ]),
                          item: _withCtx(({ item }) => [
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(item.value.pedido_frota_id), 1),
                              _createElementVNode("td", null, _toDisplayString(item.value.agendamento_id), 1),
                              _createElementVNode("td", null, _toDisplayString(item.value.filial), 1),
                              _createElementVNode("td", null, _toDisplayString(item.value.message), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["items", "headers"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, { class: "mt-2 pb-4" }),
                _createVNode(_component_v_card_actions, { class: "pb-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      class: "bg-grey rounded-lg",
                      color: "white",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.confirmDialogImport = false))
                    }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("Voltar")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      class: "bg-movida-orange rounded-lg",
                      color: "white",
                      onClick: _ctx.importCsv,
                      disabled: _ctx.btn_disabled
                    }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createTextVNode("Confirmar")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.snackbar,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.snackbar) = $event)),
      timeout: _ctx.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.closeSnack
        }, {
          default: _withCtx(() => _cache[30] || (_cache[30] = [
            _createTextVNode(" Fechar ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "ml-1" }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode(" mdi-information-outline ")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}
<template>
  <div class="d-flex justify-space-between mb-6">
    <h2 class="title">Agendamentos</h2>
    <div class="float-right">
      <v-btn class="text-white" color="movida-orange mr-3" @click="newImport">
        <v-icon> mdi-file-import </v-icon>
        Importar
      </v-btn>
      <v-btn class="text-white" color="movida-orange" @click="newSchedule">
        <v-icon> mdi-plus </v-icon>
        Novo agendamento
      </v-btn>
    </div>
  </div>
  <v-row class="mb-1 flex justify-between">
    <v-col md="3" sm="3">
      <v-text-field v-model="search.order" clearable density="compact" hide-details label="Nª do pedido" prepend-inner-icon="mdi-magnify" single-line variant="solo" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col md="3" sm="3">
      <v-text-field v-model="search.cpf" clearable density="compact" hide-details label="CPF" prepend-inner-icon="mdi-magnify" single-line variant="solo" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col md="2" sm="2">
      <v-autocomplete v-model="search.plate" label="Placa" :clearable="true" :items="allFleets" item-title="Placa" item-value="Placa" variant="solo" density="compact" multiple @update:modelValue="searchByNeedle"></v-autocomplete>
    </v-col>
    <v-col md="2" sm="2">
      <v-autocomplete v-model="search.branch" label="Loja" :clearable="true" :items="allBranch" item-title="Loja" item-value="FilialID" variant="solo" density="compact" multiple @update:modelValue="searchByNeedle"></v-autocomplete>
    </v-col>
    <v-col md="2" sm="2">
      <VueDatePicker v-model="dateSearch" range vertical :format="format" locale="pt-br" select-text="selecionar" cancel-text="cancelar" :enable-time-picker="false" @closed="searchByNeedle" @range-start="onRangeStart" @range-end="onRangeEnd" @cleared="clearCalendar"></VueDatePicker>
    </v-col>
  </v-row>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="isLoadingTable" hide-default-footer item-value="name" to="asd">
    <template v-slot:body>
      <tr v-for="item in items" :key="item.id" :class="!item.status ? 'hover-table' : ''" @mousedown="mouseDownSchedule(item)" @mouseup="mouseUpSchedule()">
        <td>{{ item.order.id }} - {{ item.client.name }}</td>
        <td>{{ item?.client?.cpf }}</td>
        <td>{{ item?.fleet?.plate }}</td>
        <td>{{ item.branch.store }}</td>
        <td>{{ format_date(item.scheduling_at) }}</td>
        <td>{{ item?.scheduling_by?.name }}</td>
        <td>{{ format_date(item.create_at) }}</td>
        <td>
          <v-chip variant="outlined" size="small" :color="getStatusColor(item.status)">
            {{ getFormatText(item.status) }}
          </v-chip>
        </td>
      </tr>
    </template>
    <template v-slot:bottom></template>
  </v-data-table-server>
  <v-row class="mt-2 d-flex align-center pb-10" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="movida-orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
    </div>
  </v-row>
  <v-container>
    <v-dialog v-model="confirmDialogReagendamento" max-width="500px">
      <v-card>
        <v-card-title class="headline mt-2">Agendamento ultrapassado</v-card-title>
        <v-card-text>Este agendamento foi realizado para uma data passada, deseja fazer o reagendamento?</v-card-text>
        <v-divider class="mt-2 pb-4"></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialogReagendamento = false">Cancelar</v-btn>
          <v-btn class="bg-red rounded-lg" @click="reschedule()">Reagendar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container>
    <v-dialog v-model="confirmDialogImport" max-width="700px">
      <v-card>
        <v-card-title class="headline mt-2"> Importar CSV </v-card-title>

        <v-card-text>
          <v-file-input v-model="csvFile" accept=".csv" label="Escolha o arquivo CSV" @change="btn_disabled = false" outlined dense required></v-file-input>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          <v-divider class="mt-2 pb-4"></v-divider>
          <v-data-table v-if="results.length > 0" :items="results" :headers="headers" class="mt-4">
            <template v-slot:headers>
              <tr>
                <th v-for="header in headersImport" :key="header.value" class="text-left">{{ header.text }}</th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.value.pedido_frota_id }}</td>
                <td>{{ item.value.agendamento_id }}</td>
                <td>{{ item.value.filial }}</td>
                <td>{{ item.value.message }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider class="mt-2 pb-4"></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn class="bg-grey rounded-lg" color="white" @click="confirmDialogImport = false">Voltar</v-btn>
          <v-btn class="bg-movida-orange rounded-lg" color="white" @click="importCsv" :disabled="btn_disabled">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
import { ref, toHandlers, toRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Schedule } from "@/models/schedule/Schedule";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import messages from "@/constants/messages";
import "@vuepic/vue-datepicker/dist/main.css";
import Papa from "papaparse";
import { getFromStorage } from "@/utils/localStorage";

@Options({
  components: {
    VueDatePicker,
  },
})
export default class ScheduleIndexView extends Vue {
  public items: Array<Schedule> = [];
  public active_item: Schedule | null = null;
  public module_channels: any;
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public messages = {};
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public confirmDialogReagendamento = false;
  public confirmDialogImport = false;
  public headers = [
    { title: "Pedido", align: "start", key: "name", sortable: false },
    { title: "CPF", align: "start", key: "cpf", sortable: false },
    { title: "Placa", align: "start", key: "plate", sortable: false },
    { title: "Loja", align: "start", key: "description", sortable: false },
    { title: "Data e Hora", align: "start", key: "datetime", sortable: false },
    { title: "Agendado por", align: "start", key: "scheduleBy", sortable: false },
    { title: "Criado em", align: "start", key: "createdAt", sortable: false },
    { title: "Status", align: "start", key: "status", sortable: false },
    // { title: "Ações", align: "end", key: "options", sortable: false },foi solicitado comentar essa funcionalidade
  ];
  public headersOrdenar = [
    { title: "Pedido", align: "start", key: "name", sortable: false },
    { title: "Loja", align: "start", key: "description", sortable: false },
  ];
  public sortSelected = "name";
  public isLoadingTable = true;
  public orderSearch = null;
  public dateSearch = ref();
  public fromSearch: any = null;
  public toSearch: any = null;
  public branchSearch: any = null;
  public allBranch: Array<any> = [];
  public allPeople: Array<any> = [];
  public allFleets: Array<any> = [];
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public search = {
    order: null,
    cpf: null,
    people: null,
    plate: null,
    branch: null,
  };

  public mouseIsDown = false;
  public idTimeout: any = null;

  public csvFile: FileList | null = null;
  public loading = false;
  public btn_disabled = false;
  public results: any[] = [];

  public headersImport = [
    { text: "Pedido ID", value: "pedido_frota_id" },
    { text: "Agendamento ID", value: "agendamento_id" },
    { text: "Filial", value: "filial" },
    { text: "Mensagem", value: "message" },
  ];

  mounted(): void {
    this.module_channels = this.getFromStorage("MODULE_CHANNEL");
    this.willMountDataPage();
    this.getFilters();
    this.messages = messages;
  }

  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.ScheduleService.getAllSchedule(this.module_channels, currentPage);

    this.unMountRequest(response);

    this.changeLoading();
  }

  mouseDownSchedule(item: Schedule) {
    console.log("mouseDownSchedule");
    console.log(item);
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if (!this.mouseIsDown) {
        this.goToSchedule(item);
      }
    }, 250);
  }

  mouseUpSchedule() {
    this.mouseIsDown = false;
  }

  nextPage(): void {
    this.changeLoading();
    this.getScheduleList(this.currentPage, this.search, this.fromSearch, this.toSearch, this.sortSelected);
  }

  prevPage(): void {
    this.changeLoading();
    this.getScheduleList(this.currentPage, this.search, this.fromSearch, this.toSearch, this.sortSelected);
  }

  loadPage(value: number): void {
    this.getScheduleList(value, this.search, this.fromSearch, this.toSearch, this.sortSelected);
    this.changeLoading();
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  async getScheduleList(page: number, search: any = {}, from: string | null = null, to: string | null = null, sortColumn: string | null = null) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.ScheduleService.getAllSchedule(this.module_channels, page, search, from, to, sortColumn);

      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }

  async importCsv(): Promise<void> {
    if (!this.csvFile) {
      this.text = "Por favor, selecione um arquivo CSV.";
      this.snackbar = true;
      return;
    }

    const userData = getFromStorage("DATA_USER");
    if (!userData) {
      this.text = "Erro ao obter dados do usuário.";
      this.snackbar = true;
      return;
    }

    const parsedData = JSON.parse(userData);
    const userId = parsedData.id;

    this.loading = true;
    this.btn_disabled = true;

    try {
      const file = this.csvFile[0];
      const arrayBuffer = await file.arrayBuffer();
      const text = new TextDecoder("windows-1252").decode(arrayBuffer);
      const parsedCsv = Papa.parse(text, { header: false });

      (parsedCsv.data as any[]).forEach((row: any[]) => {
        row[3] = userId;
      });

      const modifiedCsv = Papa.unparse(parsedCsv.data);
      console.log(modifiedCsv);
      const blob = new Blob([modifiedCsv], { type: "text/csv" });
      const dataForm = new FormData();
      dataForm.append("csv_file", blob, file.name);

      const response = await services.ScheduleService.import(dataForm);

      if (response.has_success) {
        this.btn_disabled = true;
        this.results = response.results.data.success.concat(response.results.data.errors);
        this.text = response.message;
        this.csvFile = null;
      } else {
        this.text = "Erro na importação.";
      }
    } catch (error) {
      this.text = "Erro ao enviar o arquivo. Tente novamente.";
      this.btn_disabled = false;
    } finally {
      this.loading = false;
      this.snackbar = true;
    }
  }

  async getAllBranch() {
    const response = await services.ScheduleService.getAllBranch();
    this.allBranch = response?.results;
  }

  async getFilters() {
    const response = await services.ScheduleService.getFilters();
    this.allBranch = response?.results?.branchs;
    this.allFleets = response?.results?.fleets;
  }

  format(date: any) {
    return moment(String(date[0])).format("DD/MM/YYYY") + " - " + moment(String(date[1])).format("DD/MM/YYYY");
  }

  getFromStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  goToSchedule(schedule: Schedule) {
    if (schedule.status == "concluido" || schedule.status == "cancelado") {
      return;
    }

    if (moment(schedule.scheduling_at).isBefore(moment())) {
      this.active_item = schedule;
      this.confirmDialogReagendamento = true;
      return;
    }

    this.$router.push({ path: `/schedules/update/${schedule.id}`, query: { peopleId: schedule.client.id } });
  }

  reschedule() {
    this.$router.push({ path: `/schedules/store/${this.active_item?.order.id}` });
  }

  send(schedule: any) {
    return;
  }

  newSchedule() {
    this.$router.push({ path: "/schedules/store" });
  }

  newImport() {
    // this.$router.push({ path: "/schedules/store/import" });
    this.confirmDialogImport = true;
  }

  searchByNeedle() {
    if (this.search.branch && !this.fromSearch && !this.toSearch) {
      this.text = "Por favor, selecione um periodo";
      this.snackbar = true;
      return;
    }

    this.changeLoading();
    this.items = [];
    this.getScheduleList(1, this.search, this.fromSearch, this.toSearch);
  }

  onRangeStart(value: any) {
    this.fromSearch = this.formatterDateSearch(value);
  }

  onRangeEnd(value: any) {
    this.toSearch = this.formatterDateSearch(value);
  }

  clearCalendar() {
    this.fromSearch = null;
    this.toSearch = null;

    this.searchByNeedle();
  }

  format_date(value: any) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm");
    }
  }

  private formatterDateSearch(value: any) {
    return moment(String(value)).format("YYYY-MM-DD");
  }

  public clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.getScheduleList(1);
  }

  changeSortSelected(itemSelected: string) {
    this.sortSelected = itemSelected;
    this.changeLoading();
    this.getScheduleList(this.currentPage, null, null, null, itemSelected);
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  private isNullableSort(sort: string | null) {
    return sort === null;
  }

  private resetSortSelected() {
    this.loadSortSelected();
  }

  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }

  private unMountRequest(response: any) {
    if (response.results) {
      this.currentPage = response.results.current_page;
      this.items = response.results.data as Array<Schedule>;
      this.numberOfPages = response.results.last_page;
      this.itemsPerPage = response.results.per_page;
      this.totalOfRows = response.results.total;
      this.pageFrom = response.results.current_page;
      this.pageTo = response.results.per_page;
    }
  }

  getStatusColor(status: string): string {
    switch (status) {
      case "concluido":
        return "green";
      case "no_show":
        return "orange";
      case "cancelado":
        return "red";
      case "agendado":
        return "blue";
      default:
        return "grey";
    }
  }

  getFormatText(status: string): string {
    switch (status) {
      case "concluido":
        return "Concluído";
      case "no_show":
        return "No Show";
      case "cancelado":
        return "Cancelado";
      case "agendado":
        return "Agendado";
      default:
        return "Indefinido";
    }
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
  height: 58px;
}

.dp__input {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

.dp__action_select,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #ff9800 !important;
}

.clickable {
  cursor: pointer;
}

.hover-table:hover {
  background: #dfdfdf;
  cursor: pointer;
}

.hover-table:hover td {
  background: #dfdfdf !important;
}
</style>

<template>
  <div class="d-flex justify-space-between mb-6">
    <h2 class="title">Monitoramento</h2>
    <v-btn color="movida-orange" class="text-white" @click="exportOrders()" append-icon="mdi-file-export" :loading="isLoadingExport"> Exportar </v-btn>
  </div>
  <!-- Espaço para os totais -->
  <div class="mt-15">
    <CountStatusComponent :status="status" :error="error" :allStatus="allStatus" @confirmStatus="confirmStatus" :statusToShow="statusToShow" :statusToShowModality="statusToShowModality" :loading="isLoadingStatus"></CountStatusComponent>
  </div>
  <!-- Espaço para os filtros -->
  <div class="mt-10">
    <FiltersOrdersComponent :filters="filters" :error="error" :allStatus="allStatus" @search="search" ref="filtersComponent"></FiltersOrdersComponent>
  </div>
  <!-- Espaço para a tabela -->
  <div class="mt-4">
    <ListOrdersComponent :orders="orders" :headers="headers" :isLoadingTable="isLoadingTable" @closeInformationModal="closeInformationModal" v-model:ordersPerPage="ordersPerPage"></ListOrdersComponent>
  </div>
  <!-- Espaço para a paginação -->
  <v-row class="mt-2 d-flex align-center" justify="center" v-if="totalOfRows > 0">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" color="movida-orange" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados</div>
    </div>
  </v-row>
  <!-- Mensagens toast -->
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline</v-icon>
    {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar</v-btn>
    </template>
  </v-snackbar>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import allStatus from "@/models/backoffice/AllStatus";
import * as services from "@/services";
import { Order } from "@/models/backoffice/Order";
import { Watch } from "vue-property-decorator";
import ListOrdersComponent from "./components/ListOrdersComponent.vue";
import FiltersOrdersComponent from "./components/FiltersOrdersComponent.vue";
import { FiltersOrder } from "@/models/backoffice/FiltersOrder";
import CountStatusComponent from "./components/CountStatusComponent.vue";

@Options({
  components: {
    ListOrdersComponent,
    FiltersOrdersComponent,
    CountStatusComponent,
  },
})
export default class MonitoramentoIndexView extends Vue {
  public orders: Array<Order> = [];
  public currentPage = 1;
  public numberOfPages = 10;
  public ordersPerPage = 15;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    { title: "", key: "icon", sortable: false, width: "3%" },
    { title: "Pedido", key: "pedido_id", sortable: false, width: "7%" },
    { title: "Nrº Reserva", key: "reserva_id", sortable: false, width: "7%" },
    { title: "Nrº Contrato", key: "contrato_nro", sortable: false, width: "7%" },
    { title: "Placa", key: "placa", sortable: false, width: "6%" },
    { title: "Status Monitoramento", key: "status_monitoramento", sortable: false, width: "16%", class: "text-center", align: "center" },
    { title: "Cliente", key: "nome_cliente", sortable: false, width: "7%" },
    { title: "CPF", key: "cpf_cliente", sortable: false, width: "10%" },
    { title: "Filial Retirada", key: "loja_retirada", sortable: false, width: "18%" },
    { title: "Filial Atual", key: "loja_atual", sortable: false, width: "18%" },
    { title: "Data Pedido", key: "data_criacao_pedido", sortable: false, width: "14%" },
    { title: "Data Agendamento", key: "data_agendamento", sortable: false, width: "14%" },
    { title: "Agendado Por", key: "responsavel_agendamento", sortable: false, width: "12%" },
    { title: "Final da Placa", key: "final_placa", sortable: false, width: "8%" },
    { title: "Versão", key: "versao", sortable: false, width: "12%" },
    { title: "Celular", key: "celular", sortable: false, width: "9%" },
    { title: "Cor", key: "cor", sortable: false, width: "7%" },
    { title: "", key: "", sortable: false, width: "1%" },
  ];

  public statusToShow = ["Agendar", "Agendado", "Cancelado", "Em transporte", "Veículo avariado"];
  public statusToShowModality = ["Agendar"];
  public allStatus = allStatus;
  public status: any = {};

  public isLoadingStatus = true;
  public isLoadingTable = true;
  public isLoadingExport = false;
  public needleSearch = null;
  public snackbar = false;
  public text = "";
  public messages = {};
  public timeout = 10000;
  public error: any = {
    status: [],
    type: [],
    modality: [],
    options: [],
    startDate: [],
    endDate: [],
    document: "",
  };
  public filters = {
    search: "",
    status: [],
    type: [],
    modality: [],
    options: [],
    startDate: "",
    endDate: "",
    document: "",
    dateType: "data_agendamento",
  } as FiltersOrder;

  public filterComponent: any;

  mounted(): void {
    this.loadPage(this.currentPage);
    this.filterComponent = this.$refs.filtersComponent as any;
  }

  @Watch("ordersPerPage")
  onOrdersPerPageChanged(val: number): void {
    this.ordersPerPage = val;
    this.loadPage(this.currentPage);
  }

  async getOrders(page = 1, ordersPerPage = 15, filters: FiltersOrder): Promise<void> {
    try {
      this.isLoadingTable = true;
      const response = await services.BackofficeService.getOrders(page, ordersPerPage, filters);
      this.unMountRequest(response);
      this.isLoadingTable = false;
    } catch (error: any) {
      if (typeof error === "object") {
        this.error = error;
      } else {
        this.snackbar = true;
        this.text = error;
        this.isLoadingTable = false;
      }
    }
  }

  async getStatus(filters: FiltersOrder, statusToShow = ["Agendar", "Agendado", "Em transporte", "Cancelado", "Veículo avariado"], statusToShowModality = ["Agendar"]): Promise<void> {
    try {
      this.isLoadingStatus = true;
      const response = await services.BackofficeService.getStatus(filters, statusToShow, statusToShowModality);
      this.unMountRequestStatus(response);
      this.isLoadingStatus = false;
    } catch (error: any) {
      if (typeof error === "object") {
        this.error = error;
      } else {
        this.snackbar = true;
        this.text = error;
        this.isLoadingStatus = false;
      }
    }
  }

  private unMountRequestStatus(response: any) {
    if (response) {
      let statusResponse = response.status as any;

      if (Array.isArray(statusResponse)) {
        statusResponse = {};
      }

      this.allStatus.forEach((status) => {
        if (!statusResponse[status]) {
          statusResponse[status] = {
            total: 0,
            modality: {},
          };
        }
      });

      Object.keys(statusResponse).forEach((status) => {
        if (!this.statusToShow.includes(status)) {
          delete statusResponse[status];
        }
      });

      this.status = statusResponse;
    }
  }

  private unMountRequest(response: any) {
    if (response) {
      this.currentPage = response.current_page;
      this.numberOfPages = response.last_page;
      this.orders = response.data;
      this.ordersPerPage = response.per_page;
      this.totalOfRows = response.total;
      this.pageFrom = response.from;
      this.pageTo = response.to;
    }
  }

  nextPage(): void {
    this.loadPage(this.currentPage);
  }

  prevPage(): void {
    this.loadPage(this.currentPage);
  }

  search(filters: FiltersOrder): void {
    this.filters = filters;
    this.error = {
      status: [],
      type: [],
      modality: [],
      options: [],
      startDate: [],
      endDate: [],
    };
    this.currentPage = 1;
    this.loadPage(this.currentPage);
  }

  closeInformationModal(): void {
    this.loadPage(this.currentPage);
  }

  loadPage(value: number): void {
    this.changeLoading();
    this.getOrders(value, this.ordersPerPage, this.filters);
    this.getStatus(this.filters, this.statusToShow, this.statusToShowModality);
    this.scrollToTop();
  }

  confirmStatus(statusToShow: any, statusToShowModality: any): void {
    this.statusToShow = statusToShow;
    this.statusToShowModality = statusToShowModality;
    this.getStatus(this.filters, statusToShow, statusToShowModality);
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  getFilters(): FiltersOrder {
    this.filters = this.filterComponent.getFilters();
    return this.filters;
  }

  base64ToBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }

  downloadBase64File(base64: string, fileName: string): void {
    const blob = this.base64ToBlob(base64);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  exportOrders(): void {
    this.filters = this.getFilters();
    this.error = {
      status: [],
      type: [],
      modality: [],
      options: [],
      startDate: [],
      endDate: [],
      document: "",
    };
    this.loadPage(this.currentPage);
    this.exportAndDownload();
  }

  returnTitleFile(): string {
    return "pedidos-monitoramento-" + new Date().toISOString().split("T")[0] + ".xlsx";
  }

  async exportAndDownload(): Promise<void> {
    try {
      this.isLoadingExport = true;
      const response = await services.BackofficeService.exportOrders("xlsx", this.filters);
      this.downloadBase64File(response.file, this.returnTitleFile());
      this.isLoadingExport = false;
    } catch (error: any) {
      console.log(error);
      this.isLoadingExport = false;
      this.snackbar = true;
      this.text = "Não foi possível gerar o arquivo, devido a alta quantidade de dados. Por favor, utilize filtros mais específicos ou tente novamente mais tarde.";
    }
  }
}
</script>
<style lang="css">
.mt-20 {
  margin-top: 5rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.mx-20 {
  margin-right: 15rem;
  margin-left: 15rem;
}
</style>
